<template>
    <div class="mb-3">
        <label class="form-label" for="formFile">Files</label>
        <input class="form-control" type="file" id="formFile" @change="fileUploaded" multiple>
    </div>
    <div class="d-flex flex-wrap">
        <div v-for="(file, index) in uploadedFiles" :key="index">
            <input type="text" :value="file.temporaryFileName" name="uploaded_files[]" hidden>
            <span class="badge rounded-pill bg-white border border-3 border-secondary text-text-dark m-1">{{ file.name }}<button class="ms-1 p-0 text-muted bg-transparent border-0" type="button" @click="deleteFile(index)"><i class="fa-solid fa-trash"></i></button></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "multiple-file-input",
        data() {
            return {
                uploadedFiles: []
            };
        },
        methods: {
            fileUploaded(event) {
                
                var list = this.uploadedFiles;

                Array.from(event.target.files).map(function(value) {

                    var formData = new FormData();
                    formData.append("image", value);

                    var appendedFileData = {
                        name: value.name,
                        temporaryFileName: '',
                    };

                    axios.post('/issues/media/upload', formData,
                    {
                        headers: {
                            'Content-Type' : 'multipart/form-data' 
                        }
                    }).then(response => {
                        appendedFileData.temporaryFileName = response.data.name;
                        list.push(appendedFileData);
                    })

                });
            },
            deleteFile(index) {
                this.uploadedFiles.splice(index, 1);
            }
        }
    };
</script>