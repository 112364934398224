<template>
    <div class="alert alert-danger my-3" role="alert" v-if="Object.keys(error_bag).length">
        <ul class="mb-0">
            <span class="m-0 fa-ul" v-for="(error, index) in error_bag" :key="index">
                <li v-for="(message, messageIndex) in error" :key="messageIndex"><span class="fa-li"><i class="fa-solid fa-triangle-exclamation"></i></span>{{ message }}</li>
            </span>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "errors",
        props: [
            'error_bag',
        ],
    };
</script>