import './bootstrap';
import { createApp } from 'vue/dist/vue.esm-bundler';
import Uploader from 'vue-media-upload';
import multipleFileInput from './components/multiple-file-input.vue';
import issueDetailsForm from './components/issue-details-form.vue';
import errors from './components/errors.vue';
import './assets/fontawesome/css/all.css';
import './assets/fontawesome/css/fontawesome.css';

const app = createApp({
    components: {
        errors,
        multipleFileInput,
        issueDetailsForm,
    }
});

app.component('Uploader' , Uploader);

app.mount('#app');